import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BclientsURL, CREDIT_URL, PORTEFEUILLE_URL } from "../../settings/APISetting";
import { arrayFilter, sortByProp } from "../../settings/themeSettings";






export const fetchClients = createAsyncThunk("fetch-clients", async () => {

    const response =  await fetch(`${BclientsURL}?isarchived=0`,{
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });
    if(response.status > 204){
        throw response.json();
    }

    return response.json();
});


export const creditAccount = (infos, err, cb) => {
    fetch(`${CREDIT_URL}`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(infos)
    }).then((resp) => {
        console.log(resp);
        if(resp.status > 201){
            throw  resp;
        }
       return resp.json();
    
    })
    .then(async (json) => cb(json))
    .catch(err);
}


export const  fetchClient = (id, err, cb) => {
    fetch(`${PORTEFEUILLE_URL}/${id}`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        console.log(resp);
        if(resp.status > 201 && resp.status != 0){
            throw  resp.json();
        }
       return resp.json();
    
    })
    .then(async (json) => cb(json))
    .catch(err);
} ;
export const  editClient = (id,client, err, cb) => {
    fetch(`${PORTEFEUILLE_URL}/${id}`,{
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(client)
    }).then((resp) => {
        console.log(resp);
        if(resp.status > 201 && resp.status != 0){
            throw  resp.json();
        }
       return resp.json();
    
    })
    .then(async (json) => cb(json))
    .catch(err);
} ;


export const  createClient = (client, err,cb) =>{
    fetch(PORTEFEUILLE_URL,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(client)
    }).then((resp) => {
        console.log(resp);
        if(resp.status > 201){
            throw  resp;
        }
       return resp.json();
    
    })
    .then(async (json) => cb(json))
    .catch(err);
}

const clientSlice = createSlice({
    name: 'clients',
    initialState: {clients: [], fetchStatus: '', currentClient : {}},
    reducers: {
        addClient: (state, action) =>{
            const {type, payload} = action;
            state.clients = [payload,...state.clients]
        },
        filterClients : (state, action) => {
            state.clients = arrayFilter(new RegExp(action.payload.nail), state.clients);
        
        
        }
        
    },

    extraReducers: (builder) => {
        builder.addCase(fetchClients.fulfilled, (state, action) => {
             state.clients = [...action.payload].sort((a,b) => sortByProp(a,b, 'nom'));
           console.log(action.payload);
           state.fetchStatus = 'success';
        }).addCase(fetchClients.pending, (state, action) => {
            // state.clients[]
            state.fetchStatus = 'loading';

         }).addCase(fetchClients.rejected, (state, action) => {
            // state.clients[]
            console.log(action);

            state.fetchStatus = 'error';

         });
    }
});

export default clientSlice;